import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import CheckIcon from "@material-ui/icons/Check";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import firebase from "../../firebase-config";
import MessageToast from "../../../components/MessageToast";
import UserInfoDialog from "./InfoDialog";
import UserAddForm from "./Add";
import { green, red } from "@material-ui/core/colors";
import { ExportUsersToDb } from "./ExportUsersToDb";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

const db = firebase.database();

const SearchInput = ({ filter, setFilter }) => (
  <InputBase
    placeholder="Search user by name, phone or email"
    inputProps={{
      "aria-label": "search user by name, phone or email",
    }}
    fullWidth
    value={filter}
    onInput={(e) => {
      e.target.scrollIntoView();
      setFilter(e.target.value);
    }}
    onFocus={(e) => e.target.scrollIntoView()}
  />
);

const FilterByPayment = ({ state, setState }) => {
  const v = (val) => (state === val ? "contained" : "");
  return (
    <ButtonGroup color="primary" size="small">
      <Button variant={v(0)} onClick={() => setState(0)}>
        All
      </Button>
      <Button variant={v(1)} onClick={() => setState(1)}>
        Paid
      </Button>
      <Button variant={v(-1)} onClick={() => setState(-1)}>
        No payment
      </Button>
      <Button variant={v(2)} onClick={() => setState(2)}>
        Not active
      </Button>
    </ButtonGroup>
  );
};

const AcceptDialog = ({ onConfirm, onClose }) => {
  const [amount, setAmount] = useState(100);
  const [comment, setComment] = useState("Paid in cash at the reception");

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>Cash amount</DialogTitle>
      <DialogContent>
        <Typography>Please enter amount of cash received</Typography>
        <FormControl fullWidth>
          <TextField
            value={amount}
            onInput={(e) => setAmount(e.target.value)}
          />
          <FormHelperText>Amount of cash received</FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            value={comment}
            onInput={(e) => setComment(e.target.value)}
          />
          <FormHelperText>Comment</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onConfirm({ amount, comment })}>
          Ok
        </Button>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default function UsersList() {
  const classes = useStyles();
  const { eventId, userId, token } = useParams();
  const history = useHistory();

  const [users, setUsers] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [acceptDialog, setAcceptDialog] = useState(false);
  const [userInfo, setUserInfo] = useState(undefined);
  const [filter, setFilter] = useState("");
  const [filterByPayment, setFilterByPayment] = useState(0);

  useEffect(() => {
    db.ref("event_users/" + eventId).on("value", (s) => {
      // console.log(s.val());
      setUsers(s.val());
    });
  }, [eventId]);

  useEffect(() => {
    if (users) {
      if (users[userId || token]) {
        setUserInfo({ id: userId || token, user: users[userId || token] });
      }
    }
  }, [token, userId, users]);

  const addRecord = (record) => {
    console.log("Add record to DB", record);
    db.ref("event_users/" + eventId)
      .push(record, (err) => console.log("Completed add", err))
      .then((val) => console.log("Added to DB", val))
      .catch((err) => {
        console.log(err);
        setErrorMessage(err.message);
      })
      .finally(() => console.log("Done with DB"));
  };

  const activateUser = (id) => {
    const activated = {
      date: new Date().toISOString(),
      by: firebase.auth().currentUser.displayName,
    };
    db.ref(`event_users/${eventId}/${id}/activated`)
      .set(activated)
      .then(() =>
        setUserInfo({
          ...userInfo,
          user: { ...userInfo.user, activated: activated },
        })
      )
      .catch((e) => {
        console.log(e);
        setErrorMessage(e.message);
      });
  };

  const acceptCash = (id, { amount, comment }) => {
    const payment = userInfo.user?.service || {};
    payment.service = firebase.auth().currentUser.displayName;
    payment.date = new Date().toISOString();
    payment.comment = comment ||  "Paid in cash at the reception";
    payment.amount = amount || "";
    db.ref(`event_users/${eventId}/${id}/payment`)
      .set(payment)
      .then(() =>
        setUserInfo({
          ...userInfo,
          user: { ...userInfo.user, payment },
        })
      )
      .catch((e) => {
        console.log(e);
        setErrorMessage(e.message);
      });
  };

  const handleUser = (id = null) => {
    if (id && users[id]) {
      history.push(`/${eventId}/${id}`);
      // setUserInfo({ id, user: users[id] });
    } else {
      setUserInfo(undefined);
      history.push("/" + eventId);
    }
  };

  return (
    <>
      {/*<ExportUsersToDb eventId={eventId} users={users} />*/}
      <List className={classes.root}>
        <ListItem>
          <ListItemText
            primary={<SearchInput filter={filter} setFilter={setFilter} />}
          />
          <ListItemSecondaryAction onClick={() => setFilter("")}>
            {filter ? <ClearIcon /> : <SearchIcon />}
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem>
          <FilterByPayment
            state={filterByPayment}
            setState={setFilterByPayment}
          />
        </ListItem>
        {/*<ListItem>*/}
        {/*  <ListItemSecondaryAction></ListItemSecondaryAction>*/}
        {/*</ListItem>*/}
        {users &&
          Object.entries(users)
            .filter(
              ([index, user]) =>
                !filterByPayment ||
                (filterByPayment === 2
                  ? !user.activated
                  : (user.payment?.service ? 1 : -1) + filterByPayment)
            )
            .filter(([index, user]) => {
              if (!filter) return true;
              const lf = filter.toLowerCase();
              return (
                (user.name || "").toLowerCase().includes(lf) ||
                (user.phone || "").toString().includes(filter) ||
                (user.email || "").toLowerCase().includes(lf) ||
                (user.alias || "").toLowerCase().includes(lf)
              );
            })
            .sort(([ai, a], [bi, b]) => a.name ? a.name.localeCompare(b.name) : 0)
            .map(([index, user]) => {
              const labelId = `checkbox-list-label-${index}`;

              return (
                <ListItem
                  key={index}
                  role={undefined}
                  dense
                  button
                  onClick={() => handleUser(index)}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={`Avatar n°${index}`}
                      style={user.userId ? { backgroundColor: green[500] } : user.error ? { backgroundColor: red[500] } : undefined}
                    >
                      {(user.name || "")
                        .split(" ", 2)
                        .map((w) => w.charAt(0))
                        .join("")}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    style={user.activated ? { color: green[500] } : {}}
                    id={labelId}
                    primary={user.name}
                    secondary={[user.phone, user.email]
                      .filter((v) => v)
                      .join(", ")}
                  />
                  <ListItemSecondaryAction>
                    {!!user.activated ? (
                      <CheckIcon style={{ color: green[500] }} />
                    ) : (
                      !user.payment?.service && <HelpOutlineIcon />
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
      </List>
      {<UserAddForm onSuccess={addRecord} />}
      <MessageToast message={errorMessage} />
      {userInfo && (
        <UserInfoDialog
          userInfo={userInfo}
          onActivate={activateUser}
          onAcceptCash={() => setAcceptDialog(true)}
          onClose={() => handleUser()}
        />
      )}
      {acceptDialog && (
        <AcceptDialog
          onConfirm={(val) => {
            acceptCash(userInfo.id, val);
            setAcceptDialog(false);
          }}
          onClose={() => setAcceptDialog(false)}
        />
      )}
    </>
  );
}
