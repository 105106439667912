import React from "react";
import {
  AppBar as MAppBar,
  Link,
  makeStyles,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MyFirebase from "../modules/MyFirebase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function AppBar() {
  const classes = useStyles();
  return (
    <MAppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton> */}
        <Typography variant="h6" className={classes.title}>
          <Link href="/" color="inherit">
          BDSLviv
          </Link>
        </Typography>
        <MyFirebase />
      </Toolbar>
    </MAppBar>
  );
}
