import React, { useState } from "react";
import {
  Backdrop,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";
import firebase from "../firebase-config";
import "firebase/storage";
import MessageToast from "../../components/MessageToast";

const storage = firebase.storage();
const IMAGE_PATH = "events/posters";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function AddEventForm({ onSuccess }) {
  const classes = useStyles();

  const [newValue, setNewValue] = useState({
    slug: "",
    title: "",
    description: "",
    img: "",
    users: [],
  });
  const [imageAsFile, setImageAsFile] = useState();
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile(() => image);
  };

  const handleFireBaseUpload = (e) => {
    e.preventDefault();
    setDisabled(true);
    console.log("start of upload", imageAsFile);
    // async magic goes here...
    if (imageAsFile) {
      const uploadTask = storage
        .ref(`/${IMAGE_PATH}/${imageAsFile.name}`)
        .put(imageAsFile);

      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        (snapShot) => console.log(snapShot),
        (err) => {
          console.log(err);
          setErrorMessage(err.message);
          setDisabled(false);
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          console.log("Update record with URL of image");
          storage
            .ref(IMAGE_PATH)
            .child(imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              setNewValue({ ...newValue, img: fireBaseUrl });
              onSuccess({ ...newValue, img: fireBaseUrl });
            })
            .finally(() => setDisabled(false));
        }
      );
    } else {
      onSuccess(newValue);
      setDisabled(false);
    }
  };

  return (
    <>
      <Card>
        <CardContent>
          <form>
            <FormControl disabled={disabled} fullWidth>
              <TextField
                disabled={disabled}
                name="slug"
                label="Slug"
                value={newValue.slug}
                onInput={(event) =>
                  setNewValue({ ...newValue, slug: event.target.value })
                }
              />{" "}
            </FormControl>

            <FormControl disabled={disabled} fullWidth>
              <TextField
                disabled={disabled}
                name="title"
                label="Title"
                value={newValue.title}
                onInput={(event) =>
                  setNewValue({ ...newValue, title: event.target.value })
                }
              />
            </FormControl>

            <FormControl disabled={disabled} fullWidth>
              <TextField
                disabled={disabled}
                name="description"
                label="Description"
                value={newValue.description}
                onInput={(event) =>
                  setNewValue({ ...newValue, description: event.target.value })
                }
              />{" "}
            </FormControl>

            <FormControl disabled={disabled} fullWidth>
              <InputLabel>Image</InputLabel>
              <Input type="file" onChange={handleImageAsFile} />
              <FormHelperText>Max 1MB size</FormHelperText>
            </FormControl>
            <br />
            <FormControl disabled={disabled} fullWidth>
              <Button variant="contained" onClick={handleFireBaseUpload}>
                Add
              </Button>
            </FormControl>
          </form>
        </CardContent>
      </Card>
      <Backdrop
        className={classes.backdrop}
        open={disabled}
        onClick={() => setDisabled(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MessageToast
        message={errorMessage}
        opened={!!errorMessage}
        onClose={() => setErrorMessage("")}
      />
    </>
  );
}
