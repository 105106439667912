import React, { useEffect, useState } from "react";
import { List, ListItem } from "@material-ui/core";
import EventItemList from "./ItemList";
import AddEventForm from "./Add";
import firebase from "../firebase-config";

const db = firebase.database();

export default function EventsList() {
  const [events, setEvents] = useState({});
  // const [openAddForm, setOpenAddForm] = useState(false);

  useEffect(() => {
    db.ref("events").on("value", (s) => setEvents(s.val()));
  }, []);

  const addRecord = (record) => {
    console.log("Add record to DB", record);
    db.ref("events/" + record.slug)
      .set(record, (err) => console.log("Completed add", err))
      .then((val) => console.log("Added to DB", val))
      .catch((err) => console.log(err))
      .finally(() => console.log("Done with DB"));
  };

  return (
    <div>
      <List>
        {events &&
          Object.entries(events).map(([slug, event]) => (
            <ListItem key={slug}>
              <EventItemList event={event} showActions />
            </ListItem>
          ))}
        {false && (
          <ListItem key="add">
            <AddEventForm onSuccess={addRecord} />
          </ListItem>
        )}
      </List>
      {/* 
      <Button onClick={() => setOpenAddForm(true)}>Add new</Button>

      <Dialog
        open={openAddForm}
        onClose={setOpenAddForm}
        aria-labelledby="dialog-title"
      >
        <DialogActions>
          <DialogTitle id="dialog-title">Add new event</DialogTitle>
          <Button onClick={() => setOpenAddForm(false)}>Close</Button>
        </DialogActions>
        <DialogContent></DialogContent>
      </Dialog> */}
    </div>
  );
}
