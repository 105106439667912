import React from "react";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core";
import blankPoster from "../../images/image.png";

const useStyles = makeStyles({
  root: {
    minWidth: 360,
    maxWidth: 720,
  },
  media: {
    height: 140,
  },
});

export default function EventItemList({ event, showActions = false }) {
  const classes = useStyles();
  const url = "/" + event.slug;

  return (
    <Card className={classes.root}>
      <CardActionArea href={url}>
        <CardMedia
          className={classes.media}
          image={event.img || blankPoster}
          title="Paella dish"
        />
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {event.title}
          </Typography>
        </CardContent>
      </CardActionArea>
      {showActions && (
        <CardActions>
          <Button href={url} size="small" color="primary">
            Details
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
