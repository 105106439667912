import React, { memo, useMemo, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LinearProgress from "@material-ui/core/LinearProgress";
import firebase from "../../firebase-config";

const db = firebase.database();

const ucFirst = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
const sanitizePhone = (phone) => {
  phone = phone.replaceAll(/[() -]/g, "");
  return (phone.startsWith("0") ? "+38" : phone.startsWith("38") ? "+" : "") + phone;
};

const push = (data, field, ...values) => {
  if (!data[field] || !Array.isArray(data[field])) {
    data[field] = [];
  }
  data[field].push(...values);
  data[field] = Array.from(new Set(data[field]));
};

const danceMap = {
  "Бачата": "Bachata",
  "Кізомба": "Kizomba",
  "Сальса": "Salsa",
  "Зук": "Zouk",
}

const mapDance = (dance) => danceMap[dance] || dance;

export const ExportUsersToDb = ({ eventId, users }) => {

  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState(0);

  // console.log(users);
  const onExport = async () => {
    const values = Object.entries(users).filter(([, user]) => user.phone || user.email);
    for (const [index, [id, user]] of values.entries()) {

      // if (user.email !== "yarun11@ukr.net") continue;


      let data = { names: [], cities: [], emails: [], phones: [], danceStyles: [], participant: [] };
      let userId = user.email && (await db.ref("known_emails/" + user.email.replaceAll(".", "%2E")).get()).val();
      userId = userId || (user.phone
        ? (await db.ref("known_phones/" + sanitizePhone(user.phone)).get()).val()
        : null);

      if (userId) {
        data = (await db.ref("users/" + userId).get()).val();
      }

      const [firstName, lastName, middleName] = user.name.split(" ", 3);

      const danceStyles = user.dance ? user.dance?.split(",").map(el => el.trim()) : [];

      push(data, "participant", eventId + "/" + id);
      push(data, "names", user.name);
      data.firstName = ucFirst(firstName);
      lastName && (data.lastName = ucFirst(lastName));
      middleName && (data.middleName = ucFirst(middleName));

      user.email && push(data, "emails", user.email);
      user.phone && push(data, "phones", sanitizePhone(user.phone));
      user.extra?.city && push(data, "cities", user.extra.city);
      danceStyles.length && push(data, "danceStyles", ...danceStyles.map(mapDance));

      // console.log(data);

      if (user.email === "yarun11@ukr.net") console.log("User id", userId);
      if (userId) {
        await db.ref("users/" + userId).update(data);
        if (user.email === "yarun11@ukr.net") console.log("updating existing with data", data);
      } else {
        if (user.email === "yarun11@ukr.net") console.log("pushing new data", data);
        let res = await db.ref("users").push(data);
        userId = res.key;
      }
      await db.ref("event_users/" + eventId + "/" + id).update({ userId: userId });
      data.emails?.length && await db.ref("known_emails").update(Object.fromEntries(data.emails.map(el => [el.replaceAll(".", "%2E"), userId])));
      data.phones?.length && await db.ref("known_phones").update(Object.fromEntries(data.phones.map(el => [el, userId])));
      setProgress(100 * (index + 1) / values.length);
    }
    console.log("Completed!");
  };


  return (
    <>
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        <Button onClick={() => setOpen(true)}>Export to DB</Button>
      </div>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          <FormControlLabel control={<Checkbox />} label="Select all" />
          <LinearProgress variant="determinate" value={progress} />
        </DialogTitle>
        {useMemo(() => (
          <DialogContent>
            {Object.entries(users).filter(([, user]) => user.phone || user.email).map(([id, user]) => (
              <ListItem {...{ id, user }} />
            ))}
          </DialogContent>
        ), [JSON.stringify(Object.keys(users))])}
        <DialogActions>
          <Button onClick={onExport}>Export</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ListItem = memo(({ id, user }) => (
  <DialogContentText key={"u-" + id}>
    <FormControlLabel value={id} control={<Checkbox />} label={user.name} />
    <span style={{ fontSize: 10 }}>{user.email} {user.phone}</span>
  </DialogContentText>
), (prev, next) => prev.id === next.id);