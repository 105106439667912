import React, { useState } from "react";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  makeStyles,
  NativeSelect,
  TextField,
} from "@material-ui/core";
import MessageToast from "../../../components/MessageToast";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function UserAddForm({ onSuccess }) {
  const classes = useStyles();

  const [newValue, setNewValue] = useState({
    name: "",
    email: "",
    phone: "",
    role: "guest",
    activated: false,
  });
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <form>
      <TextField
        name="name"
        label="Name"
        value={newValue.name}
        onInput={(event) =>
          setNewValue({ ...newValue, name: event.target.value })
        }
      />
      <br />
      <TextField
        name="email"
        label="E-mail"
        value={newValue.email}
        onInput={(event) =>
          setNewValue({ ...newValue, email: event.target.value })
        }
      />
      <br />
      <TextField
        name="phone"
        label="Phone"
        value={newValue.phone}
        onInput={(event) =>
          setNewValue({ ...newValue, phone: event.target.value })
        }
      />
      <br />
      <FormControl disabled={disabled}>
        <FormLabel id="check-box-new">
          <Checkbox
            edge="start"
            disableRipple={true}
            inputProps={{ "aria-labelledby": "check-box-new" }}
            checked={newValue.activated}
            onChange={(e, val) => setNewValue({ ...newValue, activated: val })}
            title="Activated"
          />
          Activated
        </FormLabel>
      </FormControl>
      <br />
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>Role</InputLabel>
        <NativeSelect
          value={newValue.role}
          onChange={(e) => setNewValue({...newValue, role: e.target.value})}
          inputProps={{
            name: "age",
            id: "age-native-helper",
          }}
        >
          <option value="guest">Guest</option>
          <option value="volunteer">Volunteer</option>
          <option value="teammate">Teem mate</option>
        </NativeSelect>
        <FormHelperText>Some important helper text</FormHelperText>
      </FormControl>
      <br />
      <FormControl disabled={disabled || !newValue.name.length}>
        <Button
          disabled={disabled || !newValue.name.length}
          variant="contained"
          onClick={() => {
            onSuccess(newValue);
          }}
        >
          Add
        </Button>
      </FormControl>

      <Backdrop
        className={classes.backdrop}
        open={disabled}
        onClick={() => setDisabled(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MessageToast
        message={errorMessage}
        opened={!!errorMessage}
        onClose={() => setErrorMessage("")}
      />
    </form>
  );
}
