import React, { useEffect, useLayoutEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import firebase from "../../firebase-config";
import MessageToast from "../../../components/MessageToast";
import { FormControl } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";

const db = firebase.database();


export const EditUserPopup = ({ userId, onClose }) => {
  const [data, setData] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  // fields errors
  const [errors, setErrors] = useState({});


  useEffect(() => {
    if (userId) {
      db.ref("users/" + userId).on("value", (s) => {
        setData(s.val() || {});
      });
    }
  }, [userId]);

  useLayoutEffect(() => {
    setDisabled(false);
    setData({});
    setErrors({});
    setErrorMessage("");
  }, [userId]);

  const processError = (error) => {
    console.error(error);
    setErrorMessage(JSON.stringify(error));
  };

  const close = (key) => {
    const emails = Object.assign({}, ...data.emails.map(el => ({ ["known_emails/" + el.replaceAll(".", "%2E")]: key })));
    db.ref().update(emails).then(() => {
      const phones = Object.assign({}, ...data.phones.map(el => ({ ["known_phones/" + el]: key })));
      db.ref().update(phones).then(onClose);
    });
  };

  const save = () => {
    setDisabled(true);
    if (userId) {
      db.ref("users/" + userId).set(data).then(() => close(userId)).catch(processError);
    } else {
      db.ref("users").push(data).then((res) => close(res.key)).catch(processError);
    }
  };

  const checkDataInDb = (field, title, value) => {
    console.log();
    if (!value) {
      setErrors({ ...errors, [field]: "" });
      return;
    }

    db.ref(`known_${field.replaceAll(".", "%2E")}/${value.replaceAll(".", "%2E")}`).get().then(v => {
      if (!userId) {
        if (v.val()) {
          setErrors({ ...errors, [field]: title + " is used by another user" });
        } else {
          setErrors({ ...errors, [field]: "" });
        }
      } else if (v.val() && v.val() !== userId) {
        setErrors({ ...errors, [field]: title + " is used by another user" });
      } else {
        setErrors({ ...errors, [field]: "" });
      }
      console.log(v.val());
    }).catch(err => {
      console.log(err);
    });
  };

  return (
    <Dialog open={userId !== undefined} onClose={onClose}>
      <DialogTitle id="form-dialog-title">{userId === null ? "Add" : "Edit"} user</DialogTitle>

      <DialogContent>
        <FormControl disabled={disabled} fullWidth>
          <TextField
            autoFocus
            disabled={disabled}
            label="Name"
            value={data.name || ""}
            onInput={(event) =>
              setData({ ...data, name: event.target.value })
            }
          />
        </FormControl>

        <FormControl component="fieldset" disabled={disabled} >
          <FormLabel component="legend">Gender</FormLabel>
          <RadioGroup
            name="sex"
            value={data.sex || "other"}
            onChange={(event) => setData({ ...data, sex: event.target.value })}
          >
            <FormControlLabel value="female" control={<Radio />} label="Female" />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>

        <FormControl disabled={disabled} fullWidth>
          <TextField
            disabled={disabled}
            label="City"
            value={data.city || ""}
            onInput={(event) =>
              setData({ ...data, city: event.target.value })
            }
          />
        </FormControl>

        <FormControl disabled={disabled} fullWidth>
          <TextField
            disabled={disabled}
            label="Email Address"
            inputMode="email"
            type="email"
            value={data.emails?.[0] || ""}
            onInput={(event) =>
              setData({ ...data, emails: [event.target.value] })
            }
            error={!!errors["emails"]}
            onBlur={() => checkDataInDb("emails", "Email", data.emails?.[0])}
          />
          <Typography color={"error"}>{errors["emails"]}</Typography>
        </FormControl>

        <FormControl disabled={disabled} fullWidth>
          <TextField
            disabled={disabled}
            label="Phone"
            inputMode="tel"
            type="phone"
            value={data.phones?.[0] || ""}
            onInput={(event) =>
              setData({ ...data, phones: [event.target.value] })
            }
            error={!!errors["phones"]}
            onBlur={() => checkDataInDb("phones", "Phone", data.phones?.[0])}
          />
          <Typography color={"error"}>{errors["phones"]}</Typography>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={save} type={"submit"} color="primary">
          Save
        </Button>
      </DialogActions>
      <MessageToast
        message={errorMessage}
        opened={!!errorMessage}
        onClose={() => setErrorMessage("")}
      />
    </Dialog>

  );
};