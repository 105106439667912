import React, { useCallback, useEffect, useState } from "react";
// import QrReader from "react-qr-reader";
import QrReader from "react-qr-scanner";
import { useHistory } from "react-router-dom";
import { Dialog, Fab, makeStyles, NativeSelect } from "@material-ui/core";
import CropFreeIcon from "@material-ui/icons/CropFree";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

export default function QrScanFab() {
  const classes = useStyles();

  const [showScanner, setShowScanner] = useState(false);
  const [cameras, setCameras] = useState({});
  const [selectedCamera, setSelectedCamera] = useState(
    localStorage.getItem
      ? (JSON.parse(localStorage.getItem("camera")) || undefined)
      : undefined
  );
  const history = useHistory();

  const onScan = (data) => {
    if (data && data.text) {
      console.log(data);
      setShowScanner(false);
      if (data.text.startsWith("http")) {
        const url = new URL(data.text || data);
        history.push(url.pathname);
      } else {
        history.push("/token/" + data.text);
      }
    }
  };

  const memoizedCallback = useCallback((mediaDevices) => {
    mediaDevices.forEach((mediaDevice) => {
      if (mediaDevice.kind === "videoinput") {
        setCameras((state) => ({
          ...state,
          [mediaDevice.deviceId]: mediaDevice,
        }));
      }
    });
  }, []);

  useEffect(() => {
    if (navigator.mediaDevices) {
      navigator.mediaDevices.enumerateDevices().then(memoizedCallback);
    }
  }, [memoizedCallback]);

  return (
    <div>
      <Fab
        color="secondary"
        className={classes.fab}
        onClick={() => setShowScanner(!showScanner)}
      >
        <CropFreeIcon />
      </Fab>
      {showScanner && (
        <Dialog
          open={true}
          onClose={() => setShowScanner(!showScanner)}
          fullWidth
          maxWidth="xs"
        >
          <QrReader
            delay={300}
            onError={console.log}
            onScan={onScan}
            style={{ width: "100%" }}
            constraints={selectedCamera}
          />
          {cameras && (
            <NativeSelect
              fullWidth
              value={selectedCamera?.video?.deviceId?.exact}
              onChange={(e) => {
                const constraints = {
                  video: { deviceId: { exact: e.target.value } },
                  audio: false,
                };
                localStorage.setItem && localStorage.setItem("camera", JSON.stringify(constraints));
                setSelectedCamera(constraints);
              }}
            >
              {Object.values(cameras).map((camera) => (
                <option key={"cam-" + camera.deviceId} value={camera.deviceId}>
                  {camera.label}
                </option>
              ))}
            </NativeSelect>
          )}
        </Dialog>
      )}
    </div>
  );
}
