import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import UsersList from "./Users/List";
import EventItemList from "./ItemList";
import firebase from "../firebase-config";
import NotFound from "../NotFound";

const db = firebase.database();
const auth = firebase.auth();
const admins = [
  "62lw0WZEUrU3BLqzdC54f1p8MBl1",
  "LUi3RScyv4XYvuAn6yo6eiAOy012",
  "QnVowmPu7kRzuYZVmCU2F7HIqh93",
  "TSWmCv5PTUVcUPoejYzFD4ErQhu2",
  "zT9A1GDZtpVnZlbcsfM4tB2hug42",
  "VYOAFOzVnsR4ZlHsPs3wkDSBQII3",
  "G9kJgaRm3WMunNAKdCgGzPhvPW12",
  "MNQK4KQa5QQo1CEmVqI2k1u4GV43",
  "XvR8KWWi56f82BQkBifNkxZtsjF3",
  "hv8H6p00VaMEbVacBPQn5FGJ1633", // Ігор
  "XWsWlkVNn7UIqRTcXLaAbF4ELtf2", // Марта
  "VPrUGxdOHjXLAJHn2zVh0pDcgTb2", // bdslviv.app
  "foaBNlRmpAP7jfeP892qDYcCLlq2", // Кавчик
  "FefMdNdiQXOMuKKVx9PItNtsYuJ2", // malek2777
  "UcT9HiT6weZF6tYdjRZGTsvbXJ03", // Лісовський
  "OCGvzBB2rwUMJWEHydxk4I5o0Mf2", // yasvetlanka23
  "HYXeSZcezCf6EE9I0cFhasDqlXm1", // lliahovich2014
  "jGNKnLeoTwRt0Vgw37JstJO0eoB2", // Богач
  "6O97OMOk4Jak3IPyJeKPUdjIo2p2", // Люта
  "frZCQrqjvLSP4iW1GO3BnSd1Xwu2", // Одинець
  "3bM2DVGd6McvqrhNOCWilLlrxdr2", // semopt@gmail.com
  "qdXegGH2p0UclClkMFEwJCxjxQo1", // marina11061996
  "YD8ShTekDfR0jqtmIpXeZsgNGG72", // Василенко
  "Xq2qtOszjPfKshdWUulO7GnkEX82", // Гончарук
  "pxsIY4Nf2eSjwATD1uxFmMFtvyS2", // Бортник

  "XbLYuiYUtDM5DTB5BWpdAs091Q05", // test acc local
];

export default function EventInfo() {
  const { eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [showUsers, setShowUsers] = useState(false);

  useEffect(() => {
    db.ref("events/" + eventId).on("value", (s) => {
      console.log(s.val());
      setEvent(s.val());
    });
  }, [eventId]);

  useEffect(() => {
    auth.onAuthStateChanged(() => setShowUsers(admins.includes(auth.currentUser?.uid)));
  }, []);

  if (event) {
    return (
      <div>
        <div style={{ paddingTop: "10px" }}>
          <EventItemList event={event} />
        </div>
        {showUsers && (
          <div>
            <h3>Participants</h3>
            <UsersList />
          </div>
        )}
      </div>
    );
  }

  return <NotFound />;
}
