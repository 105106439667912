import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { AddRounded } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { EditUserPopup } from "./Edit";
import firebase from "../../firebase-config";

const useStyles = makeStyles({
  actionsBar: {
    marginBottom: 16,
  },
  table: {
    minWidth: 650,
  },
});

const db = firebase.database();

export const UsersList = () => {
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState();
  const [rows, setRows] = useState({});

  useEffect(
    () => db.ref("users").on("value", (s) => setRows(s.val() || {})),
    []
  );

  return (
    <>
      <EditUserPopup userId={selectedUser} onClose={() => setSelectedUser(undefined)} />
      <Box className={classes.actionsBar}>
        <Button size="small" onClick={() => setSelectedUser(null)}>
          <AddRounded />
          <Typography>Add user</Typography>
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="users list">
          <TableHead>
            <TableRow>
              <TableCell>Full name</TableCell>
              <TableCell>Dances</TableCell>
              <TableCell align="right">Sex</TableCell>
              <TableCell align="right">City</TableCell>
              <TableCell align="right">Phones</TableCell>
              <TableCell align="right">Emails</TableCell>
              <TableCell align="right">Participant of</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(rows).map(([id, row], i) => (
              <TableRow key={"user" + i} onClick={() => setSelectedUser(id)}>
                <TableCell component="th" scope="row">
                  {row.name || row.names?.map((el, k) => (<div key={"n" + i + k}>{el}</div>))}
                </TableCell>
                <TableCell>{row.danceStyles?.join(", ")}</TableCell>
                <TableCell align="right">{row.sex}</TableCell>
                <TableCell align="right">
                  {row.city || row.cities?.map((el, k) => (<div key={"c" + i + k}>{el}</div>))}
                </TableCell>
                <TableCell align="right">
                  {row.phones?.map((el, k) => (<div key={"p" + i + k}>{el}</div>))}
                </TableCell>
                <TableCell align="right">
                  {row.emails?.map((el, k) => (<div key={"e" + i + k}>{el}</div>))}
                </TableCell>
                <TableCell>
                  {row.participant?.map((el, k) => (<div key={"par" + i + k}>{el}</div>))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};