import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  Avatar,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import TodayIcon from "@material-ui/icons/Today";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PaymentIcon from "@material-ui/icons/Payment";
import StyleIcon from "@material-ui/icons/Style";
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import LocationCityIcon from '@material-ui/icons/LocationCity';

// import PaymentIcon from "@material-ui/icons/Payment";

const useStyles = makeStyles((theme) => ({
  dialog: {
    overflow: "visible",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const dummyFormatter = (v) => v;
const dateFormatter = (val) => new Date(val).toLocaleString();
const moneyFormatter = (val) => val.toString() + " UAH";

const userFields = {
  email: { label: "E-mail", icon: <MailIcon /> },
  phone: { label: "Phone", icon: <PhoneIcon /> },
  dance: { label: "Dances", icon: <DirectionsRunIcon /> },
  registration_date: {
    label: "Registration",
    icon: <TodayIcon />,
    formatter: dateFormatter,
  },
  "extra.city": { label: "City", icon: <LocationCityIcon/> },
  "extra.pass": { label: "Pass", icon: <StyleIcon /> },
  "extra.prepay.amount": {
    label: "Prepayment",
    icon: <PaymentIcon />,
    formatter: moneyFormatter,
    subtitle: "extra.ticket",
  },
  "extra.transfer": { label: "Transfer", icon: <DirectionsBusIcon/> },
};
const paymentFields = {
  service: { label: "Service:" },
  amount: { label: "Amount:", formatter: moneyFormatter },
  date: { label: "Date:", formatter: dateFormatter },
  comment: { label: "Comment:" },
};
const activatedFields = {
  by: { label: "By:" },
  date: { label: "At:", formatter: dateFormatter },
};

const TableGrid = ({ fields, data, firstCol = 3 }) =>
  Object.entries(fields)
    .filter(([field, config]) => data[field])
    .map(([field, config]) => (
      <Grid key={"grid-" + field} container item direction="row" spacing={2} alignItems="center">
        <Grid item xs={firstCol}>
          <FormControl>{config.label}</FormControl>
        </Grid>
        <Grid item xs={12 - firstCol}>
          <FormControl>
            {(config.formatter || dummyFormatter)(data[field])}
          </FormControl>
        </Grid>
      </Grid>
    ));

const Payments = ({ data, onAccept }) => {
  if (!data?.service) {
    return (
      <Grid item>
        <Typography variant="h6" color="error">No payment found!</Typography>
        <Typography>{data?.comment}</Typography>
        <Button variant="contained" onClick={onAccept}>Received in cash</Button>
      </Grid>
    );
  }

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item>
        <Typography variant="caption">Payment info:</Typography>
      </Grid>
      <TableGrid fields={paymentFields} data={data} />
    </Grid>
  );
};

const ActivationInfo = ({ data }) => {
  console.log(data);
  return (
    <Grid container direction="column" spacing={0}>
      <Grid item>
        <Typography variant="caption">Activated:</Typography>
      </Grid>
      <TableGrid fields={activatedFields} data={data} firstCol={2} />
    </Grid>
  );
};

export default function UserInfoDialog({
  userInfo,
  onAcceptCash,
  onActivate,
  onClose,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!userInfo.user.payment?.service) {
      setExpanded(true);
    }
  }, [userInfo.user.payment?.service]);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="form-dialog-title">
        <Grid
          key="avatar"
          container
          direction="row"
          spacing={2}
          alignItems="center"
        >
          <Grid item xs={2}>
            <Avatar alt={"Avatar of user"}>
              {userInfo.user.name
                .split(" ", 2)
                .map((w) => w.charAt(0))
                .join("")}
            </Avatar>
          </Grid>
          <Grid item xs={10}>
            <FormControl fullWidth>{userInfo.user.name}</FormControl>
            <FormHelperText>{userInfo.user.role}</FormHelperText>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Grid container direction="column" spacing={2}>
          {Object.entries(userFields)
            .filter(([field, config]) => {
              const value = field.split(".").reduce((o, j) => o[j] || {}, userInfo.user);
              return typeof value !== "object" && value;
            })
            .map(([field, config]) => (
              <Grid
                key={field}
                item
                container
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={2}>
                  <FormControl>{config.icon}</FormControl>
                </Grid>
                <Grid item xs={10}>
                  <FormControl>
                    {(config.formatter || dummyFormatter)(field.split(".").reduce((o, j) => o[j] || {}, userInfo.user)).toString()}
                    {config.subtitle && (typeof config.subtitle.split(".").reduce((o, j) => o[j] || {}, userInfo.user) !== "object") && (
                      <FormHelperText>
                        {config.subtitle.split(".").reduce((o, j) => o[j] || {}, userInfo.user).toString()}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </DialogContent>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider />
        <DialogContent className={classes.dialog}>
          <Payments
            data={userInfo.user.payment}
            onAccept={() => onAcceptCash(userInfo.id)}
          />
        </DialogContent>
        {userInfo.user.activated && (
          <>
            <Divider />
            <DialogContent className={classes.dialog}>
              <ActivationInfo data={userInfo.user.activated} />{" "}
            </DialogContent>
          </>
        )}
      </Collapse>
      <DialogActions>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={() => setExpanded(!expanded)}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
        <div style={{ flex: 1 }}></div>

        {userInfo.user.payment?.service ? (
          userInfo.user.activated ? (
            <Button disabled>Activated</Button>
          ) : (
            <Button variant="contained" onClick={() => onActivate(userInfo.id)}>
              Activate
            </Button>
          )
        ) : (
          <Button disabled>No payment</Button>
        )}
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
