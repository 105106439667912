import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";

if (!firebase.apps.length) {
  var firebaseConfig = {
    apiKey: "AIzaSyDjop61hOQ5RbqFKcuRpz9VCcgDsSe1NA4",
    authDomain: "bdsl-events.firebaseapp.com",
    projectId: "bdsl-events",
    storageBucket: "bdsl-events.appspot.com",
    messagingSenderId: "378111764756",
    appId: "1:378111764756:web:6a9f0f632c9089f4fe3a01",
    databaseURL: "https://bdsl-events-default-rtdb.europe-west1.firebasedatabase.app",
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

if (window?.location?.hostname === "localhost") {
  console.log(3121234);
  firebase.auth().useEmulator("http://localhost:9099/");
  firebase.database().useEmulator("localhost", 9000);
  firebase.storage().useEmulator("localhost", 9199);

}


export default firebase;
