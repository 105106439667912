import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import firebase from "../../firebase-config";
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const db = firebase.database();

const Row = ({ row }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.count}</TableCell>
        <TableCell align="right">{row.sum}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Records
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Pass</TableCell>
                    <TableCell align="right">Prepay&nbsp;(UAH)</TableCell>
                    <TableCell align="right">Amount&nbsp;(UAH)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.records.map((line) => (
                    <TableRow key={line.name}>
                      <TableCell component="th" scope="row">
                        {line.name}
                      </TableCell>
                      <TableCell>{line.phone}</TableCell>
                      <TableCell>{line.email}</TableCell>
                      <TableCell>{line.extra?.pass}</TableCell>
                      <TableCell align="right">{line.extra?.prepay?.amount}</TableCell>
                      <TableCell align="right">{line.payment?.amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function ReportByUser() {
  const {eventId} = useParams();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    db.ref("event_users/" + eventId).on("value", (s) => {
      const result = {};
      Object.entries(s.val() ||  {}).map(([key, user]) => {
        const service = user.payment?.service || "No payment";
        if (!result[service]) {
          result[service] = {name: service, count: 0, sum: 0, records: []};
        }
        result[service].count += 1;
        result[service].sum += parseInt(user.payment?.amount || 0);
        result[service].records.push(user);
      })
      setRows(result);
    });
  }, [eventId]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Name</TableCell>
            <TableCell align="right">Count</TableCell>
            <TableCell align="right">Sum&nbsp;(UAH)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.values(rows).map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}