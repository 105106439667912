import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import firebase from "./firebase-config";
import "firebase/auth";

export default function MyFirebase({color = "inherit"}) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
      console.log(11111, user);
    });
  }, []);

  var provider = new firebase.auth.GoogleAuthProvider();

  const signInPopup = () => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        /** @type {firebase.auth.OAuthCredential} */
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;

        console.log(token, credential, user);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        console.log(errorCode, errorMessage, email, credential);
      });
  };

  if (user) {
    return (
      <>
        <div>Hello, {user.displayName}</div>
        <Button color={color} onClick={() => firebase.auth().signOut()}>
          Logout
        </Button>
      </>
    );
  }

  return (
    <Button color={color} onClick={signInPopup}>
      Log in with Google
    </Button>
  );
}
