import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, } from "react-router-dom";
import { Container, CssBaseline, makeStyles } from "@material-ui/core";
import EventsList from "./modules/Event/List";
import Login from "./modules/Auth/Login";
import EventInfo from "./modules/Event/Info";
import UsersList from "./modules/Event/Users/List";
// import SendInviteUsersList from "./modules/Invite/Users/List";
import QrScanFab from "./components/QrScanFab";
import AppBar from "./components/AppBar";
import TokenResolver from "./modules/Token/Resolver";
import UsersImport from "./modules/Event/Users/Import";
import ReportByUser from "./modules/Event/Reports/ByUser";
import { Admin } from "./modules/Admin/Admin";
import Toolbar from "@material-ui/core/Toolbar";

const useStyles = makeStyles((theme) => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Wrapper = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <AppBar />
      <Container component="main" maxWidth="xs">
        <Toolbar />
        <div className={classes.paper}>{children}</div>
      </Container>
      <QrScanFab />
      <div style={{ textAlign: "center", padding: "10px" }}>BDSLviv, 2021</div>
    </>
  );
};

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/auth/login">
          <Wrapper>
            <Login />
          </Wrapper>
        </Route>
        <Route path="/admin">
          <Admin />
        </Route>
        <Route path="/token/:token">
          <Wrapper>
            <TokenResolver />
          </Wrapper>
        </Route>
        {/* <Route path="/:eventId/invites/send">
          <Wrapper><SendInviteUsersList /></Wrapper>
        </Route> */}
        <Route path="/:eventId/reports/byUser">
          <ReportByUser />

        </Route>
        <Route path="/:eventId/users/import">
          <UsersImport />
        </Route>
        <Route path="/:eventId/users/:userId">
          <Wrapper>
            <UsersList />
          </Wrapper>
        </Route>
        <Route path="/:eventId/users">
          <Wrapper>
            <UsersList />
          </Wrapper>
        </Route>
        <Route path="/:eventId/:token">
          <Wrapper>
            <EventInfo />
          </Wrapper>
        </Route>
        <Route path="/:eventId">
          <Wrapper>
            <EventInfo />
          </Wrapper>
        </Route>
        <Route path="/">
          <Wrapper>
            <EventsList />
          </Wrapper>
        </Route>
        <Redirect to="/" />
      </Switch>
    </Router>
  );
}
