import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Typography } from "@material-ui/core";
import firebase from "../firebase-config";

const db = firebase.database();

export default function TokenResolver() {
  const history = useHistory();
  const { token } = useParams();
  const [tokenNotFound, setTokenNotFound] = useState(false);

  useEffect(() => {
    db.ref("tokens/" + token)
      .get()
      .then(r => {
        console.log(r, r.val());
        if (r.exists()) {
          console.log(r.exists());
          const { eventId, userId } = r.val();
          history.push(["", eventId, userId].join("/"));
        } else {
          setTokenNotFound(true);
        }
      });
  }, []);

  return tokenNotFound ? (
      <Typography variant="h5" style={{}}>
        Token not found! 😢
      </Typography>)
    : (
      <Typography variant="h5" style={{}}>
        Processing token...
      </Typography>
    );
}