import React from "react";
import {Typography } from "@material-ui/core";

export default function NotFound() {
  return (
    <Typography variant="h5" style={{}}>
      Event not found
    </Typography>
  );
}
