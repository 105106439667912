import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactJson from "react-json-view";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import _set from "lodash/set";
import firebase from "../../firebase-config";

const db = firebase.database();

const VALUE_SEPARATOR = ";";

const mapping = {
  "№ платежу": "extra.prepay.number",
  "Час платежу": "extra.prepay.timestamp",
  "Статус транзакції": "extra.prepay.transaction_status",
  "Метод оплати": "extra.prepay.method",
  "Тип квитка": "extra.pass",
  "Номер квитка": "extra.ticket",
  // "Прохід": "activate",
  "Ціна": "extra.prepay.amount",
  "ПІБ клієнта": "name",
  "Email": "email",
  "Номер телефону": "phone",
  "Танці": "dance",
  "Трансфер": "extra.transfer",
  "Місто": "extra.city",
};

export default function UsersImport() {
  const { eventId } = useParams();

  const [data, setData] = useState("");
  // const [users, setUsers] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [keys, setKeys] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [selected, setSelected] = useState("name");

  // useEffect(() => {
  //   db.ref("event_users/" + eventId).on("value", (s) => {
  //     setUsers(s.val());
  //   });
  // }, [eventId]);

  const processData = () => {
    const rows = data.split("\n");

    setKeys(processRow(rows.shift()).map(el => mapping[el]));

    setJsonData(rows.filter(v => v).map(row => {
      const result = {};
      const values = processRow(row);
      const obj = keys.reduce((obj, key, index) => {
        return key ? ({ ...obj, [key]: values[index] }) : obj;
      }, {});
      Object.entries(obj || {}).forEach(([key, value]) => {
        _set(result, key, value);
      });
      return result;
    }));
    console.log(jsonData);
  };

  const processRow = (row) => {
    return row.split(VALUE_SEPARATOR).map(v => v.replace(/""/g, "\"").replace(/^"|"$/g, ""));
  };

  return (
    <>
      <Typography variant={"h4"}>Import users</Typography>
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <TextField
              fullWidth
              label="CSV data"
              multiline
              rows={8}
              value={data}
              onChange={event => setData(event.target.value)}
            />
          </Grid>
        </Grid>
        <Button onClick={processData}>Convert</Button>
        <Button onClick={() => setCollapsed(false)}>Expand all</Button>
        <Button onClick={() => setCollapsed(true)}>Collapse all</Button>
        <Select value={selected} onChange={e => setSelected(e.target.value)}>
          {Object.entries(mapping)
            .filter(([k, v]) => v)
            .map(([title, value]) => <MenuItem key={value} value={value}>{title}</MenuItem>)
          }
        </Select>
        <Button onClick={() => updateDb(eventId, jsonData)}>Make changes in DB</Button>
      </Paper>
      <Paper>
        {(jsonData || []).map((data, index) => {
            const hasValue = false;
            // Object.values(users).some((user) => user.phone == data.phone || user.email == data.email);
            return (
              <Grid key={"jd-" + index} container spacing={2}>
                <Grid item>
                  <FormControlLabel control={
                    <Checkbox defaultChecked disabled />
                  } label={"Insert"} />
                </Grid>
                <Grid item>
                  <FormControl>
                    <ReactJson src={data} collapsed={collapsed} name={data[selected]} />
                  </FormControl>
                </Grid>
              </Grid>);
          }
        )}
      </Paper>
    </>
  );
}

const updateDb = (eventId, data = []) => {
  const key = (v) => btoa(unescape(encodeURIComponent((v || Math.random().toString()) + Math.random().toString())))
    .replace("/", "")
    .substring(6);
  const values = data.reduce((obj, v) => ({ ...obj, [key(v.extra?.prepay?.number)]: v }), {});
  const tokens = Object.fromEntries(
    Object.entries(values)
      .filter(([k, v]) => v.extra?.ticket)
      .map(([userId, v]) => [v.extra?.ticket, { eventId, userId }]).filter(v => v)
  );

  db.ref("event_users/" + eventId).set(values).then(
    () => db.ref("tokens").get().then(r => {
      const result = { ...r.val(), ...tokens };
      console.log(result);
      return db.ref("tokens").set(result);
    })
  ).then(() => alert("Database is updated"));
};

