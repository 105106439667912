import React from "react";
import { Container, CssBaseline } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";

import AppBar from "../../components/AppBar";
import { AdminDrawer } from "./AdminDrawer";
import { UsersList } from "./Users/List";

export const Admin = () => (
  <div style={{ display: "flex" }}>
    <CssBaseline />
    <AppBar />
    <AdminDrawer />
    <main style={{ flexGrow: 1, paddingTop: 24 }} >
      <Toolbar />
      <Container >
        <UsersList />
      </Container>
    </main>
  </div>
);