import React from "react";
import { Snackbar } from "@material-ui/core";

export default function MessageToast({ message, opened, onClose }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={opened === undefined ? !!message : opened }
      autoHideDuration={6000}
      onClose={onClose}
      message={message}
    />
  );
}
